/* tslint:disable */
/* eslint-disable */
/**
 * aktiverhanapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TermsResponse
 */
export interface TermsResponse {
    /**
     * 
     * @type {string}
     * @memberof TermsResponse
     */
    terms?: string | null;
}

export function TermsResponseFromJSON(json: any): TermsResponse {
    return TermsResponseFromJSONTyped(json, false);
}

export function TermsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'terms': !exists(json, 'terms') ? undefined : json['terms'],
    };
}

export function TermsResponseToJSON(value?: TermsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'terms': value.terms,
    };
}

