/* tslint:disable */
/* eslint-disable */
/**
 * aktiverhanapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CheckResponse,
    CheckResponseFromJSON,
    CheckResponseToJSON,
    CodeResponse,
    CodeResponseFromJSON,
    CodeResponseToJSON,
    EnactResponse,
    EnactResponseFromJSON,
    EnactResponseToJSON,
    FailResponse,
    FailResponseFromJSON,
    FailResponseToJSON,
    StartRequest,
    StartRequestFromJSON,
    StartRequestToJSON,
    StartResponse,
    StartResponseFromJSON,
    StartResponseToJSON,
    TermsResponse,
    TermsResponseFromJSON,
    TermsResponseToJSON,
    ValidateResponse,
    ValidateResponseFromJSON,
    ValidateResponseToJSON,
} from '../models';

export interface ApiSessionPostRequest {
    startRequest?: StartRequest;
}

export interface ApiSessionSessionIdCheckGetRequest {
    sessionId: string;
}

export interface ApiSessionSessionIdCodePatchRequest {
    sessionId: string;
    code?: string;
}

export interface ApiSessionSessionIdEnactPatchRequest {
    sessionId: string;
}

export interface ApiSessionSessionIdFailPatchRequest {
    sessionId: string;
}

export interface ApiSessionSessionIdTermsGetRequest {
    sessionId: string;
}

export interface ApiSessionSessionIdValidateGetRequest {
    sessionId: string;
}

/**
 * 
 */
export class SessionApi extends runtime.BaseAPI {

    /**
     */
    async apiSessionPostRaw(requestParameters: ApiSessionPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StartResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartRequestToJSON(requestParameters.startRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StartResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSessionPost(requestParameters: ApiSessionPostRequest, initOverrides?: RequestInit): Promise<StartResponse> {
        const response = await this.apiSessionPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSessionSessionIdCheckGetRaw(requestParameters: ApiSessionSessionIdCheckGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CheckResponse>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling apiSessionSessionIdCheckGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Session/{sessionId}/check`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSessionSessionIdCheckGet(requestParameters: ApiSessionSessionIdCheckGetRequest, initOverrides?: RequestInit): Promise<CheckResponse> {
        const response = await this.apiSessionSessionIdCheckGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSessionSessionIdCodePatchRaw(requestParameters: ApiSessionSessionIdCodePatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CodeResponse>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling apiSessionSessionIdCodePatch.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Session/{sessionId}/code`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CodeResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSessionSessionIdCodePatch(requestParameters: ApiSessionSessionIdCodePatchRequest, initOverrides?: RequestInit): Promise<CodeResponse> {
        const response = await this.apiSessionSessionIdCodePatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSessionSessionIdEnactPatchRaw(requestParameters: ApiSessionSessionIdEnactPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EnactResponse>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling apiSessionSessionIdEnactPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Session/{sessionId}/enact`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnactResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSessionSessionIdEnactPatch(requestParameters: ApiSessionSessionIdEnactPatchRequest, initOverrides?: RequestInit): Promise<EnactResponse> {
        const response = await this.apiSessionSessionIdEnactPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSessionSessionIdFailPatchRaw(requestParameters: ApiSessionSessionIdFailPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FailResponse>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling apiSessionSessionIdFailPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Session/{sessionId}/fail`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FailResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSessionSessionIdFailPatch(requestParameters: ApiSessionSessionIdFailPatchRequest, initOverrides?: RequestInit): Promise<FailResponse> {
        const response = await this.apiSessionSessionIdFailPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSessionSessionIdTermsGetRaw(requestParameters: ApiSessionSessionIdTermsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TermsResponse>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling apiSessionSessionIdTermsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Session/{sessionId}/terms`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TermsResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSessionSessionIdTermsGet(requestParameters: ApiSessionSessionIdTermsGetRequest, initOverrides?: RequestInit): Promise<TermsResponse> {
        const response = await this.apiSessionSessionIdTermsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiSessionSessionIdValidateGetRaw(requestParameters: ApiSessionSessionIdValidateGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ValidateResponse>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling apiSessionSessionIdValidateGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Session/{sessionId}/validate`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiSessionSessionIdValidateGet(requestParameters: ApiSessionSessionIdValidateGetRequest, initOverrides?: RequestInit): Promise<ValidateResponse> {
        const response = await this.apiSessionSessionIdValidateGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
