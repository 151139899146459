/* tslint:disable */
/* eslint-disable */
/**
 * aktiverhanapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConsentStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export function ConsentStatusFromJSON(json: any): ConsentStatus {
    return ConsentStatusFromJSONTyped(json, false);
}

export function ConsentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentStatus {
    return json as ConsentStatus;
}

export function ConsentStatusToJSON(value?: ConsentStatus | null): any {
    return value as any;
}

