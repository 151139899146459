import { navigate } from "gatsby";
import { createTrackedSelector } from 'react-tracked';
import create from "zustand";
import { persist } from 'zustand/middleware';
import { combineAndImmer, getConfig } from '.';
import { ApiLookupPostRequest, Configuration, LookupApi, AuthMethods, CheckResponse, ValidateResponse, TermsResponse, SessionApi, ApiSessionPostRequest, ApiSessionSessionIdCheckGetRequest, ApiSessionSessionIdValidateGetRequest, ApiSessionSessionIdTermsGetRequest, ApiSessionSessionIdEnactPatchRequest, ApiSessionSessionIdCodePatchRequest, ApiSessionSessionIdFailPatchRequest, SessionStatus } from '../service';

const initialState = {
    isLoading: false,
    authUrl: null as string,
    sessionId: null as string,
    sessionType: null as number,
    authMethods: null as AuthMethods,
    sessionStatus: null as CheckResponse | ValidateResponse,
    terms: null as TermsResponse,
};

export const sessionStore = create(persist(combineAndImmer(initialState, (set, get) => ({
    setSessionId: (sessionId: string) => {
        set((state) => { state.sessionId = sessionId; });
    },
    lookup: async (request: ApiLookupPostRequest) => {
        set((state) => { state.isLoading = true; });
        try {
            const api: LookupApi = new LookupApi(new Configuration(getConfig()));
            const result = await api.apiLookupPost(request);
            set((state) => {
                state.isLoading = false;
                state.sessionId = result.sessionId;
                state.authMethods = result.authMethods;
                state.sessionStatus = { sessionStatuses: SessionStatus.NUMBER_1 }
                state.sessionType = result.sessionType;
            });
            return result;
        } catch (error) {
            set((state) => { state.isLoading = false; });
            throw error;
        }
    },
    start: async (request: ApiSessionPostRequest) => {
        set((state) => { state.isLoading = true; });
        try {
            const api: SessionApi = new SessionApi(new Configuration(getConfig()));
            const result = await api.apiSessionPost(request);
            set((state) => {
                state.isLoading = false;
                state.sessionId = result.sessionId;
                state.authUrl = result.authUrl;
            });
            return result;
        } catch (error) {
            set((state) => { state.isLoading = false; });
            throw error;
        }
    },
    check: async (request: ApiSessionSessionIdCheckGetRequest) => {
        set((state) => { state.isLoading = true; });
        try {
            const api: SessionApi = new SessionApi(new Configuration(getConfig()));
            const result = await api.apiSessionSessionIdCheckGet(request);
            set((state) => {
                state.isLoading = false;
                state.sessionStatus = result;
            });

            return result;
        } catch (error) {
            set((state) => { state.isLoading = false; });
            throw error;
        }
    },
    fail: async (request: ApiSessionSessionIdFailPatchRequest) => {
        set((state) => { state.isLoading = true; });
        try {
            const api: SessionApi = new SessionApi(new Configuration(getConfig()));
            const result = await api.apiSessionSessionIdFailPatch(request);
            set((state) => {
                state.isLoading = false;
                state.sessionStatus = result;
            });
            return result;
        } catch (error) {
            set((state) => {
                state.isLoading = false;
            });
            throw error;
        }
    },
    validate: async (request: ApiSessionSessionIdValidateGetRequest) => {
        set((state) => { state.isLoading = true; });
        try {
            const api: SessionApi = new SessionApi(new Configuration(getConfig()));
            const result = await api.apiSessionSessionIdValidateGet(request);
            set((state) => {
                state.isLoading = false;
                state.sessionStatus = result;
            });
            return result;
        } catch (error) {
            set((state) => { state.isLoading = false; });
            throw error;
        }
    },
    getTerms: async (request: ApiSessionSessionIdTermsGetRequest) => {
        set((state) => { state.isLoading = true; });
        try {
            const api: SessionApi = new SessionApi(new Configuration(getConfig()));
            const result = await api.apiSessionSessionIdTermsGet(request);
            set((state) => {
                state.isLoading = false;
                state.terms = result;
            });
            return result;
        } catch (error) {
            set((state) => { state.isLoading = false; });
            throw error;
        }
    },
    code: async (request: ApiSessionSessionIdCodePatchRequest) => {
        set((state) => { state.isLoading = true; });
        try {
            const api: SessionApi = new SessionApi(new Configuration(getConfig()));
            const result = await api.apiSessionSessionIdCodePatch(request);
            set((state) => {
                state.isLoading = false;
            });
            return result;
        } catch (error) {
            set((state) => { state.isLoading = false; });
            throw error;
        }
    },
    enact: async (request: ApiSessionSessionIdEnactPatchRequest) => {
        set((state) => { state.isLoading = true; });
        try {
            const api: SessionApi = new SessionApi(new Configuration(getConfig()));
            const result = await api.apiSessionSessionIdEnactPatch(request);
            set((state) => {
                state.isLoading = false;
            });
            return result;
        } catch (error) {
            set((state) => { state.isLoading = false; });
            throw error;
        }
    },
}))
    , {
        name: "sessionPersist",
        // whitelist: [""],
        version: 1
    }));

export const useTrackedSessionStoreStore = createTrackedSelector(sessionStore);
