/* tslint:disable */
/* eslint-disable */
/**
 * aktiverhanapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthMethods,
    AuthMethodsFromJSON,
    AuthMethodsFromJSONTyped,
    AuthMethodsToJSON,
} from './';

/**
 * 
 * @export
 * @interface LookupResponse
 */
export interface LookupResponse {
    /**
     * 
     * @type {string}
     * @memberof LookupResponse
     */
    sessionId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LookupResponse
     */
    sessionType?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LookupResponse
     */
    canActivate?: boolean;
    /**
     * 
     * @type {AuthMethods}
     * @memberof LookupResponse
     */
    authMethods?: AuthMethods | null;
}

export function LookupResponseFromJSON(json: any): LookupResponse {
    return LookupResponseFromJSONTyped(json, false);
}

export function LookupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LookupResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'sessionType': !exists(json, 'sessionType') ? undefined : json['sessionType'],
        'canActivate': !exists(json, 'canActivate') ? undefined : json['canActivate'],
        'authMethods': !exists(json, 'authMethods') ? undefined : AuthMethodsFromJSON(json['authMethods']),
    };
}

export function LookupResponseToJSON(value?: LookupResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'sessionType': value.sessionType,
        'canActivate': value.canActivate,
        'authMethods': AuthMethodsToJSON(value.authMethods),
    };
}

