/* tslint:disable */
/* eslint-disable */
/**
 * aktiverhanapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartRequest
 */
export interface StartRequest {
    /**
     * 
     * @type {string}
     * @memberof StartRequest
     */
    sessionId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StartRequest
     */
    authMethod?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StartRequest
     */
    isEmbeded?: boolean;
}

export function StartRequestFromJSON(json: any): StartRequest {
    return StartRequestFromJSONTyped(json, false);
}

export function StartRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'authMethod': !exists(json, 'authMethod') ? undefined : json['authMethod'],
        'isEmbeded': !exists(json, 'isEmbeded') ? undefined : json['isEmbeded'],
    };
}

export function StartRequestToJSON(value?: StartRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'authMethod': value.authMethod,
        'isEmbeded': value.isEmbeded,
    };
}

