
import { devtools, persist } from 'zustand/middleware';
import { createTrackedSelector } from 'react-tracked';
import produce from "immer";
import create, {
    State,
    SetState,
    GetState,
    StoreApi
    // StateCreator
} from "zustand";
import { ConfigurationParameters } from '../service';

export type StateCreator<
    T extends State,
    CustomSetState = SetState<T>,
    U extends State = T
    > = (set: CustomSetState, get: GetState<T>, api: StoreApi<T>) => U;

export const immer = <T extends State, U extends State>(
    config: StateCreator<T, (fn: (draft: T) => void) => void, U>
): StateCreator<T, SetState<T>, U> => (set, get, api) =>
        config((fn) => set(produce(fn) as (state: T) => T), get, api);

export const combine = <PrimaryState extends State, SecondaryState extends State>(
    initialState: PrimaryState,
    create: (
        set: SetState<PrimaryState>,
        get: GetState<PrimaryState>,
        api: StoreApi<PrimaryState>
    ) => SecondaryState
): StateCreator<PrimaryState & SecondaryState> => (set, get, api) =>
        Object.assign(
            {},
            initialState,
            create(
                set as SetState<PrimaryState>,
                get as GetState<PrimaryState>,
                api as StoreApi<PrimaryState>
            )
        );

export const combineAndImmer = <
    PrimaryState extends State,
    SecondaryState extends State
>(
    initialState: PrimaryState,
    config: StateCreator<
        PrimaryState,
        (fn: (draft: PrimaryState) => void) => void,
        SecondaryState
    >
): StateCreator<PrimaryState & SecondaryState> => {
    return combine(initialState, immer(config));
};

export function getConfig(): ConfigurationParameters {
    return {
        basePath: process.env.GATSBY_AKTIVERHANAPI_URL
    };
};