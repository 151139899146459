/* tslint:disable */
/* eslint-disable */
/**
 * aktiverhanapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LookupRequest,
    LookupRequestFromJSON,
    LookupRequestToJSON,
    LookupResponse,
    LookupResponseFromJSON,
    LookupResponseToJSON,
} from '../models';

export interface ApiLookupPostRequest {
    lookupRequest?: LookupRequest;
}

/**
 * 
 */
export class LookupApi extends runtime.BaseAPI {

    /**
     */
    async apiLookupPostRaw(requestParameters: ApiLookupPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LookupResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Lookup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LookupRequestToJSON(requestParameters.lookupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LookupResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiLookupPost(requestParameters: ApiLookupPostRequest, initOverrides?: RequestInit): Promise<LookupResponse> {
        const response = await this.apiLookupPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
