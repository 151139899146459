/* tslint:disable */
/* eslint-disable */
/**
 * aktiverhanapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum IndentificationStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

export function IndentificationStatusFromJSON(json: any): IndentificationStatus {
    return IndentificationStatusFromJSONTyped(json, false);
}

export function IndentificationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): IndentificationStatus {
    return json as IndentificationStatus;
}

export function IndentificationStatusToJSON(value?: IndentificationStatus | null): any {
    return value as any;
}

