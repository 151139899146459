/* tslint:disable */
/* eslint-disable */
/**
 * aktiverhanapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartResponse
 */
export interface StartResponse {
    /**
     * 
     * @type {string}
     * @memberof StartResponse
     */
    sessionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartResponse
     */
    authUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StartResponse
     */
    authId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StartResponse
     */
    authMethod?: number;
}

export function StartResponseFromJSON(json: any): StartResponse {
    return StartResponseFromJSONTyped(json, false);
}

export function StartResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'authUrl': !exists(json, 'authUrl') ? undefined : json['authUrl'],
        'authId': !exists(json, 'authId') ? undefined : json['authId'],
        'authMethod': !exists(json, 'authMethod') ? undefined : json['authMethod'],
    };
}

export function StartResponseToJSON(value?: StartResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'authUrl': value.authUrl,
        'authId': value.authId,
        'authMethod': value.authMethod,
    };
}

