/* tslint:disable */
/* eslint-disable */
/**
 * aktiverhanapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConsentStatus,
    ConsentStatusFromJSON,
    ConsentStatusFromJSONTyped,
    ConsentStatusToJSON,
    IndentificationStatus,
    IndentificationStatusFromJSON,
    IndentificationStatusFromJSONTyped,
    IndentificationStatusToJSON,
    SessionStatus,
    SessionStatusFromJSON,
    SessionStatusFromJSONTyped,
    SessionStatusToJSON,
    ValidationStatus,
    ValidationStatusFromJSON,
    ValidationStatusFromJSONTyped,
    ValidationStatusToJSON,
    WorkerStatus,
    WorkerStatusFromJSON,
    WorkerStatusFromJSONTyped,
    WorkerStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface FailResponse
 */
export interface FailResponse {
    /**
     * 
     * @type {IndentificationStatus}
     * @memberof FailResponse
     */
    indentificationStatuses?: IndentificationStatus | null;
    /**
     * 
     * @type {ConsentStatus}
     * @memberof FailResponse
     */
    consentStatuses?: ConsentStatus | null;
    /**
     * 
     * @type {ValidationStatus}
     * @memberof FailResponse
     */
    validationStatus?: ValidationStatus | null;
    /**
     * 
     * @type {WorkerStatus}
     * @memberof FailResponse
     */
    workerStatuses?: WorkerStatus | null;
    /**
     * 
     * @type {SessionStatus}
     * @memberof FailResponse
     */
    sessionStatuses?: SessionStatus | null;
}

export function FailResponseFromJSON(json: any): FailResponse {
    return FailResponseFromJSONTyped(json, false);
}

export function FailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'indentificationStatuses': !exists(json, 'indentificationStatuses') ? undefined : IndentificationStatusFromJSON(json['indentificationStatuses']),
        'consentStatuses': !exists(json, 'consentStatuses') ? undefined : ConsentStatusFromJSON(json['consentStatuses']),
        'validationStatus': !exists(json, 'validationStatus') ? undefined : ValidationStatusFromJSON(json['validationStatus']),
        'workerStatuses': !exists(json, 'workerStatuses') ? undefined : WorkerStatusFromJSON(json['workerStatuses']),
        'sessionStatuses': !exists(json, 'sessionStatuses') ? undefined : SessionStatusFromJSON(json['sessionStatuses']),
    };
}

export function FailResponseToJSON(value?: FailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'indentificationStatuses': IndentificationStatusToJSON(value.indentificationStatuses),
        'consentStatuses': ConsentStatusToJSON(value.consentStatuses),
        'validationStatus': ValidationStatusToJSON(value.validationStatus),
        'workerStatuses': WorkerStatusToJSON(value.workerStatuses),
        'sessionStatuses': SessionStatusToJSON(value.sessionStatuses),
    };
}

