/* tslint:disable */
/* eslint-disable */
/**
 * aktiverhanapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthMethods
 */
export interface AuthMethods {
    /**
     * 
     * @type {boolean}
     * @memberof AuthMethods
     */
    bankIdWeb?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthMethods
     */
    bankIdMobile?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthMethods
     */
    vipps?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthMethods
     */
    lastPartMeteringPointId?: boolean;
}

export function AuthMethodsFromJSON(json: any): AuthMethods {
    return AuthMethodsFromJSONTyped(json, false);
}

export function AuthMethodsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthMethods {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bankIdWeb': !exists(json, 'bankIdWeb') ? undefined : json['bankIdWeb'],
        'bankIdMobile': !exists(json, 'bankIdMobile') ? undefined : json['bankIdMobile'],
        'vipps': !exists(json, 'vipps') ? undefined : json['vipps'],
        'lastPartMeteringPointId': !exists(json, 'lastPartMeteringPointId') ? undefined : json['lastPartMeteringPointId'],
    };
}

export function AuthMethodsToJSON(value?: AuthMethods | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bankIdWeb': value.bankIdWeb,
        'bankIdMobile': value.bankIdMobile,
        'vipps': value.vipps,
        'lastPartMeteringPointId': value.lastPartMeteringPointId,
    };
}

